import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';
import OBR from "@owlbear-rodeo/sdk";

const key = "blog.whitebeard.owlbear/character"

var owlbear_storage = {
  save_character: function (character) {
    var dict = {}
    dict[key] = JSON.stringify(character)
    OBR.player.setMetadata(dict);
    console.log('Character data saved:', character);
  },
  load_character: function () {
    return OBR.player.getMetadata().then(data => {
      console.log("Metadata loaded ", data)
      if (data && data[key]) {
        return JSON.parse(data[key]);
      } else {
        return {}
      }
    });
  }
}

var local_storage = {
  save_character: function (character) {
    localStorage.setItem(key, JSON.stringify(character));
  },
  load_character: function () {
    var character = localStorage.getItem(key);
    if (character) {
      return new Promise((resolve) => {
        resolve(JSON.parse(character))
      })
    } else {
      return new Promise((resolve) => resolve({}));
    }
  }
}

//const storage = OBR.isAvailable ? owlbear_storage : local_storage
const storage = local_storage
const onReady = OBR.isAvailable ? OBR.onReady : (callback) => { callback() }

onReady(() => {
  storage.load_character().then(character => {
    var app = Elm.Main.init({
      node: document.getElementById('root'),
      flags: (character || {})
    });
    app.ports && app.ports.saveData && app.ports.saveData.subscribe(function (state) {
      storage.save_character(state);
    });
  });
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
